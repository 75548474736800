<template>
    <div class="shop_main">
        <div class="shop_search_menu">
            <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
            <i class="el-icon-arrow-right"></i>
            <span class="on">新闻资讯</span>
        </div>

        <img :src="'./img/banner_new.png'" class="page_banner" />
        <div class="shop_help">
            <div class="head">
                <h3 class="title">新闻资讯</h3>

<!--                <div class="contact">-->
<!--                    <span class="text">无法解决您的问题？请联系</span>-->
<!--                    <span class="text phone">客服热线：<span class="number">{{consumerPhone}}</span></span>-->
<!--                </div>-->

                <div class="search">
                    <el-input v-model="listQuery.title" placeholder="请输入搜索关键词" @keyup.enter.native="toSearch"></el-input>
                    <el-button type="primary" @click="toSearch">搜索</el-button>
                </div>
            </div>

            <div class="class">
                <span class="text">分类：</span>
                <a href="javascript:void(0)" class="item" :class="{on: listQuery.category === ''}" @click="toChangeClass('')">全部</a>
                <a href="javascript:void(0)" class="item" :class="{on: listQuery.category === '1'}" @click="toChangeClass('1')">药材资讯</a>
                <a href="javascript:void(0)" class="item" :class="{on: listQuery.category === '2'}" @click="toChangeClass('2')">药市行情</a>
                <a href="javascript:void(0)" class="item" :class="{on: listQuery.category === '3'}" @click="toChangeClass('3')">政策法规</a>
<!--                <a href="javascript:void(0)" class="item" :class="{on: listQuery.category === '4'}" @click="toChangeClass('4')">企业动态</a>-->
            </div>

            <div class="content" v-loading="isRequestLoading" style="min-height: 308px">
                <ul>
                    <li v-for="(item, index) in listData" :key="index">
                        <a href="javascript:void(0)" @click="$router.push({path: '/shop/article/detail', query: {type: '2', id: item.id}})">
                            <span class="title"><span> · </span>{{item.title}}</span>
                            <span v-if="item.createdAt" class="time">{{item.createdAt.split(' ')[0]}}</span>
                        </a>
                    </li>
                </ul>
            </div>

            <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange"/>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {getArticle} from '../../api/shop/article'
  import {consumerPhone} from '../../utils/config'
  const pagination = () => import('../../components/pagination/shop')
  export default {
    name: "shopHelp",
    data () {
      return {
        listQuery: {
          title: '',
          type: '2',
          category: '',
          page: 1,
          pageSize: 18
        },
        pages: {
          total: 0,
          pages: 0
        },
        isRequestLoading: false,
        listData: [],
        consumerPhone: consumerPhone
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
      })
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      getList () {
        this.isRequestLoading = true
        getArticle(this.listQuery).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.listData = res.data.records
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      pageChange (e) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toChangeClass (e) {
        this.listQuery.category = e;
        this.listQuery.page = 1;
        this.getList()
      },
      toSearch () {
        this.listQuery.page = 1;
        this.getList()
      }
    },
    components: {
      pagination
    }
  }
</script>
